.loc {
  margin-bottom: $space-5;
  &__items {
    display: flex;
    flex-direction: column;
    gap: $space-5;
    @media screen and (max-width: 767px) {
      gap: $space-3;
    }
  }
  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: $space-3;
    overflow: hidden;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: $space-1;
      background-color: $color-grey-6;
      border-radius: $border-radius;
      border: 1px solid $color-grey-4;
    }
  }

  &__mapa {
    overflow: hidden;
    @media screen and (max-width: 991px) {
    }
    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 11;
    }
  }

  &__content {
    @media screen and (max-width: 991px) {
      padding: $font-size;
    }
  }

  &__reservas {
    padding: $space-1;
    background-color: $color-grey-6;
    margin-bottom: $font-size;
    border-radius: $font-size;
    @media screen and (min-width: 992px) {
      margin-left: -$space-1;
    }
    @media screen and (max-width: 991px) {
      background: $color-white;
    }
  }
  &__reservas-title {
    font-size: $header-5;
    margin-bottom: $font-size-smaller;
    font-weight: $font-weight-bold;
  }
  &__telefono {
    font-size: $font-size;
    margin-bottom: $font-size-smaller;
  }
  &__horario-reservas {
    font-size: $font-size-small;
    strong {
      font-weight: $font-weight-bold;
      display: block;
    }
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
  }
  &__direccion {
    font-size: $font-size-smaller;
    color: $color-grey-3;
    margin-bottom: $space-1;
  }

  &__tipo {
    font-size: $header-5;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }
  &__horario {
    font-size: $font-size-small;
    color: $color-grey-2;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      margin-bottom: initial;
    }
  }
}
.container {
}
