.page-title {
  padding: $space-3 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__h {
    font-size: $display-2;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
  }
}

.cartas {
  padding-top: $space-4;
  margin-bottom: $space-3;
  @media screen and (max-width: 767px) {
    padding-top: $space-2;
    margin-bottom: $space-2;
  }
  &__cartas {
    margin-bottom: $space-1;
    display: flex;
    flex-direction: column;
    gap: $space-4;
  }
  &__carta {
  }
  &__subtitulo {
    font-size: $header-5;
    margin-bottom: $font-size-smaller;
    font-weight: $font-weight-bold;
    color: $color-primary;
  }

  &__titulo {
    font-size: $header-1;
    margin-bottom: $font-size;
    font-weight: $font-weight-bold;
    color: $color-grey-2;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }

  &__galeria {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $font-size;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      gap: $font-size;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border: 1px solid $color-grey-5;
      padding: 5px;
      background-color: $color-background;
      border-radius: 5px;
      @media screen and (max-width: 767px) {
        border: 0;
        padding: 0;
      }
    }
  }
  &__cartae {
    margin-bottom: initial;
    margin-top: $space-1;
  }
  &__cartae-titulo {
    font-size: $font-size-smaller;
    margin-bottom: $font-size-small;
  }
  &__cartae-items {
    display: flex;
    flex-wrap: wrap;
    gap: $font-size;
    @media screen and (max-width: 575px) {
      gap: 10px;
    }
    .boton {
      // @media screen and (max-width: 767px) {
      //   width: 100%;
      //   text-align: center;
      // }
    }
  }
}
