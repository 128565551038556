@mixin wrapper {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $space-3;
  padding-right: $space-3;
  @media screen and (max-width: 1199px) {
    padding-left: $space-1;
    padding-right: $space-1;
  }
  @media screen and (max-width: 1199px) {
    padding-left: $font-size;
    padding-right: $font-size;
  }
}
