.nav-principal {
  &__nav {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li.menu-item {
      a {
        display: inline-flex;
        padding: 0 $font-size-big;
        font-weight: $font-weight-medium;
        color: $color-grey-1;
        text-decoration: none;
        &:hover {
          color: $color-primary-dark;
        }
      }
      &--cita {
        margin-left: 1rem;
        a {
          background-color: $color-dark;
          color: $color-background !important;
          padding: 0.3rem 1.2rem;
          font-size: $font-size-small;
          border-radius: 50px;
        }
      }
    }
  }
}

.nav-off {
  margin-bottom: $space-1;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    li.menu-item {
      // margin-bottom: 5px;
      a {
        font-size: $header-2;
        color: $color-white;
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }
  }
}

.offcanvas {
  &-title {
    font-size: $font-size-smaller;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
  }
  &-logo {
    img {
      width: 100px;
      height: auto;
    }
  }
}
