.header {
  padding: $font-size 0;
  width: 100%;
  transition: $trans;
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 10;
  &--sticky {
    padding: $font-size 0;
    background-color: $color-white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $space-2;
    padding-right: $space-2;
    gap: $space-1;
    @media screen and (max-width: 1399px) {
      justify-content: flex-start;
    }
    @media screen and (max-width: 991px) {
      justify-content: flex-start;
      padding-left: $space-1;
      padding-right: $space-1;
      gap: $font-size;
    }
  }
  &__burger {
    flex: 1;
    img {
      width: 25px;
      height: auto;
    }
    @media screen and (max-width: 1399px) {
      flex: initial;
    }
  }
  &__logo {
    flex: 0.5;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1399px) {
      flex: 1;
      justify-content: flex-start;
    }
    img {
      width: 150px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 120px;
        height: auto;
      }
    }
  }
  &__nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 1399px) {
      flex: initial;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__contacto {
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      margin-left: auto;
    }
  }
}

// 46 i 32
