.hero {
  position: relative;
  height: calc(100vh - 63px);
  // border: 1px solid red;
  overflow: hidden;
  &__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__overlay {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__container {
    position: relative;
    z-index: 2;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__content {
    text-align: center;
    max-width: 700px;
  }

  &__titulo {
    font-size: $display-3;
    @media screen and (max-width: 767px) {
      font-size: $display-4;
    }
  }

  &__actions {
    margin-top: $space-2;
  }
  &__down {
    cursor: pointer;
    position: absolute;
    bottom: $space-3;
    z-index: 2;
    margin-left: calc((50% - 15px));
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.square {
  display: flex;
  height: 80vh;
  width: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  &__overlay {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: red;
    width: 100%;
    position: relative;
    margin: $space-2;
    @media screen and (max-width: 767px) {
      margin: $space-1;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    color: $color-white;
    margin-bottom: $space-2;
    margin-left: $space-3;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
      margin-left: $space-1;
      padding-right: $space-1;
    }
  }
  &__titulo {
    font-size: $display-3;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    span {
      font-weight: $font-weight-normal;
      color: rgba(255, 255, 255, 0.7);
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
}

.claim {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__container {
    @include wrapper;
  }

  &__content {
    @media screen and (min-width: 992px) {
      width: 70%;
    }
  }

  &__titulo {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    line-height: 1.5;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
      margin-bottom: $space-1;
    }
    span {
      color: $color-primary;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $space-1;
  }
}

.grid-fotos {
  &__container {
    margin-left: $space-2;
    margin-right: $space-2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      margin-left: $space-1;
      margin-right: $space-1;
    }
  }

  &__foto {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 4 / 3;
    }
    &--2x {
      img {
        @media screen and (min-width: 992px) {
          aspect-ratio: 1 / 1.5;
        }
      }
    }
  }

  &__text {
    padding: $space-2;
    @media screen and (max-width: 767px) {
      padding: $space-1;
    }
  }

  &__content {
    position: sticky;
    top: $space-4;
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
  }

  &__subtitulo {
    font-size: $header-5;
    line-height: 1.6;
  }
}

.galeria-slider {
  padding: $space-4 0;
  &__header {
    @include wrapper;
    margin-bottom: $space-2;
  }
  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (min-width: 992px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: 0;
    }
  }
  &__item {
    padding-left: calc($font-size / 2);
    padding-right: calc($font-size / 2);
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
      object-fit: cover;
      // border-radius: $font-size;
    }
  }
}

.slick-slider {
  padding: $space-1 0;
  .slick-dots {
    bottom: -50px !important;
  }
}

.locales {
  padding-bottom: $space-3;
  &__container {
    @include wrapper;
  }

  &__items {
    display: flex;
    justify-content: space-evenly;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      padding: $font-size;
      border-radius: $border-radius;
      background-color: $color-grey-6;
    }
  }

  &__logo {
    max-width: 50%;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size;
    }
    img {
      max-width: 100%;
      width: 130px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 110px;
      }
    }
  }

  &__nombre {
    font-size: $header-5;
    font-weight: $font-weight-bold;
  }

  &__tipo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  &__direccion {
    font-size: $header-6;
    font-weight: $font-weight-normal;
  }
}
