.footer {
  background-color: $color-grey-6;
  padding: $space-2 0 $space-2;
  &__container {
    @include wrapper;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__copy {
    margin-bottom: 5px;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: $font-size-smaller;
    }
  }
  &__ruta {
    margin-bottom: $space-1;
    img {
      width: 170px;
      height: auto;
    }
  }
  &__privacidad {
    font-size: $font-size-smaller;
  }
}

.widget-social {
  margin-bottom: $space-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }
}

.widget-contact {
  margin-bottom: $space-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }
  .textwidget {
    p {
      font-size: $font-size-small;
    }
    .tel {
      font-size: $font-size-small;
      color: $color-white;
      background: $color-grey-2;
      border-radius: 50px;
      display: inline-block;
      padding: 0px 8px;
      text-decoration: none;
    }
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
